import React from "react"

// gatsby libraries
import { useStaticQuery, graphql } from "gatsby"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"

// @fortawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faClipboardList,
  faEnvelopeOpenText,
  faBallot,
} from "@fortawesome/pro-solid-svg-icons"

// page styles
import documentsStyle from "assets/jss/material-kit-pro-react/views/registrationPageSections/documentsStyle.jsx"
const useStyles = makeStyles(documentsStyle)

export default function SectionDocuments() {
  const {
    cooksLetter,
    cookOffRules,
    cookOffGuidelines,
  } = useStaticQuery(graphql`
    query getContestDocuments {
      cooksLetter: file(
        relativePath: {
          eq: "registration/documents/chilimania-cooks-letter.pdf"
        }
      ) {
        publicURL
        name
      }
      cookOffRules: file(
        relativePath: {
          eq: "registration/documents/chilimania-cook-off-rules.pdf"
        }
      ) {
        publicURL
        name
      }
      cookOffGuidelines: file(
        relativePath: { eq: "registration/documents/cook-off-guidelines.pdf" }
      ) {
        publicURL
        name
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={10} md={8}>
            <h2 className={classes.title}>Download Contest Information</h2>
          </GridItem>
        </GridContainer>
        <GridContainer justifyContent="center">
          <GridItem md={4} sm={4} xs={10} className={classes.documentsItem}>
            <FontAwesomeIcon
              className={classes.documentIcons}
              icon={faEnvelopeOpenText}
            />
            <h4 className={classes.subtitle}>Chilimania Cooks Letter</h4>
            <a href={cooksLetter.publicURL}>
              <Button className={classes.documentsButton} color="primary">
                <PictureAsPdfIcon />
                Download
              </Button>
            </a>
          </GridItem>
          <GridItem md={4} sm={4} xs={10} className={classes.documentsItem}>
            <FontAwesomeIcon
              className={classes.documentIcons}
              icon={faClipboardList}
            />
            <h4 className={classes.subtitle}>Chilimania Cook-Off Rules</h4>
            <a href={cookOffRules.publicURL}>
              <Button className={classes.documentsButton} color="primary">
                <PictureAsPdfIcon />
                Download
              </Button>
            </a>
          </GridItem>
          <GridItem md={4} sm={4} xs={10} className={classes.documentsItem}>
            <FontAwesomeIcon
              className={classes.documentIcons}
              icon={faBallot}
            />
            <h4 className={classes.subtitle}>Cook-off Guidelines</h4>
            <a href={cookOffGuidelines.publicURL}>
              <Button className={classes.documentsButtonBottom} color="primary">
                <PictureAsPdfIcon />
                Download
              </Button>
            </a>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
